.Headline {
  text-align: right;
  text-transform: uppercase;
  font-family: "Staatliches", sans-serif;
  letter-spacing: 0.125rem;
  width: calc(100% + 0.125rem);
  font-size: 4.5em;
  line-height: 1.125;
  font-weight: 600;
  padding-top: 10px;
}

.Body {
  font-family: "Space Mono", monospace;
  letter-spacing: -0.025rem;
  width: 100%;
  font-size: 1.125em;
  line-height: 1.625;
  font-weight: 400;
  text-align: left;
}

a {
  text-decoration-line: underline;
}

b {
  font-weight: 1000;
}